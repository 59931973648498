
// vue
import { computed, onMounted, ref } from 'vue';

// icons
import { alertCircleOutline, link, unlink, refresh, } from 'ionicons/icons';

// components
import { IonCard, IonCardHeader, IonItem, IonIcon, IonLabel, IonCardContent, IonThumbnail, IonBadge, IonChip, IonButton, loadingController, } from '@ionic/vue';
import DashboardPane from "@/components/dashboards/DashboardPane.vue";
import WidgetTextValue from "@/components/dashboards/WidgetTextValue.vue";

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { utilsDevice } from '@/composables/utilsDevice';
import { useStore } from 'vuex';

// services
import AssetService from '@/services/AssetService';

export default {
  props: [
    "title", "icon",
    "initSimImg", "simImg",
    "selectedAssetPart",
  ],
  emits: ["unlinkDevice", "linkDevice"],
  components: { IonCard, IonCardHeader, IonItem, IonIcon, IonLabel, IonCardContent, IonThumbnail, IonBadge, IonChip, IonButton,
                DashboardPane, WidgetTextValue, },
  setup(props, { emit, slots }) {
    const store = useStore();
    const { t } = useI18n();
    const { tStr, getRelativeDate, presentPrompt } = utils();
    const { formatVal, getRotateDegreeFromAcc, getAssetAngleDiff, } = utilsDevice();
    
    const userLoggedIn = computed(() => store.state.loggedIn);
    const latestAssetPartVal = (key) => ((props.selectedAssetPart.latestAssetData || {})[key]);

    return {
      // icons
      alertCircleOutline, link, unlink, refresh,

      // variables
      userLoggedIn,

      // methods
      emit,
      t, tStr,
      getRelativeDate,
      formatVal,
      getRotateDegreeFromAcc,
      latestAssetPartVal,
      getAssetAngleDiff,
      getAssetPartStatus: () => {
        const getStatusObj = (text, color) => ({ text, color });
        const { latestAssetData } = props.selectedAssetPart;
        const minTimestamp = new Date(new Date().getTime() - 15*3600*1000); // data should be at least 15 hours ago
        if (!latestAssetData || new Date(latestAssetData?.timestamp) < minTimestamp) {
          return getStatusObj(tStr('沒有數據', 'No Data'), 'dark'); // sensor probably disconnected
        }
        const { statusColor, statusText } = getAssetAngleDiff(props.selectedAssetPart);
        return getStatusObj(statusText, statusColor);
      },
      resetAssetInitAcc: async () => {
        presentPrompt("", tStr("確認重設正常傾角數值?", "Confirm resetting the value of normal inclination angle?"), async () => {
          const loading = await loadingController.create({});
          await loading.present();
          const { id, parentId, } = props.selectedAssetPart;
          const res = await AssetService.updateAsset({ id, parentId, initAccX: '', initAccY: '', initAccZ: '' });
          store.commit('upsertAssets', [res]);
          loading.dismiss();
        });
      },
    }
  }
}
