import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a59390e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"font-size":"0.9rem"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    style: {"--background":"rgba(23, 81, 120, 0.6)"},
    lines: "none",
    detail: $props.showDetailBtn,
    button: true,
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.showDetailBtn ? undefined : $setup.copyText($props.value)))
  }, {
    default: _withCtx(() => [
      ($props.iconSrc && ($props.iconSrc.includes('image/svg') || $props.iconSrc.includes('.svg')))
        ? (_openBlock(), _createBlock(_component_ion_icon, {
            key: 0,
            style: {"margin-inline-end":"16px"},
            slot: "start",
            size: "large",
            src: $props.iconSrc
          }, null, 8, ["src"]))
        : ($props.iconSrc)
          ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
              key: 1,
              style: {"--size":"32px"},
              slot: "start"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", { src: $props.iconSrc }, null, 8, _hoisted_1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
        default: _withCtx(() => [
          _createElementVNode("h1", _hoisted_2, _toDisplayString($props.title), 1),
          _withDirectives(_createElementVNode("p", { style: {"font-size":"0.6rem"} }, _toDisplayString($props.description), 513), [
            [_vShow, $props.description]
          ])
        ]),
        _: 1
      }),
      ($props.showLinkDeviceBtn == true)
        ? (_openBlock(), _createBlock(_component_ion_button, {
            key: 2,
            style: {"--border-radius":"0"},
            slot: "end",
            color: $props.badgeColor
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tStr('按此連接監測裝置', 'Connect Sensor')) + " ", 1),
              _createVNode(_component_ion_icon, {
                slot: "end",
                icon: $setup.link
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["color"]))
        : ($props.value != null)
          ? (_openBlock(), _createBlock(_component_ion_badge, {
              key: 3,
              color: $props.badgeColor,
              slot: "end",
              class: _normalizeClass({ 'transparent-background': !$props.badgeColor })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.value), 1)
              ]),
              _: 1
            }, 8, ["color", "class"]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["detail"]))
}