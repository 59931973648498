
// icons
import { link, } from 'ionicons/icons';

// components
import { IonItem, IonIcon, IonLabel, IonBadge, IonThumbnail, IonButton, } from '@ionic/vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

export default {
  props: [
    "title",
    "description",
    "value",
    "iconSrc",
    "badgeColor",
    "showLinkDeviceBtn",
    "showDetailBtn",
  ],
  emits: ["clickedActionBtn"],
  components: { IonItem, IonIcon, IonLabel, IonBadge, IonThumbnail, IonButton, },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { tStr, copyText } = utils();

    //updateAsset (linkedDeviceId)

    return {
      // icons
      link,

      // variables

      // methods
      t, tStr, copyText,
      onClickActionBtn: () => {
        emit('clickedActionBtn');
      },
    }
  }
}
