import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { uploadImage } from '@/services/utils';

export default {
  /**
   * AI / GPT
   */
  async retryGPTProcessUploadedImages(job, targetFiles = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('wins-retryGPTProcessUploadedImages')({ job, targetFiles, })).data;
  },

  /**
   * BD report / WINS / work locations
   */
  async linkBatchUploadJobFiles(files) {
    return (await firebase.app().functions('asia-east2').httpsCallable('wins-linkBatchUploadJobFiles')({ files })).data;
  },
  async updateBatchUploadJobFile(id, linkedWorkPhotoRecordId) {
    return (await firebase.app().functions('asia-east2').httpsCallable('wins-updateBatchUploadJobFile')({ id, linkedWorkPhotoRecordId })).data;
  },
  async insertBatchUploadJob(projectId, files, step = "", workLocationType = "", recognitionMode = "", mappings = []) {
    return (await firebase.app().functions('asia-east2').httpsCallable('wins-insertBatchUploadJob')({ projectId, files, step, workLocationType, recognitionMode, mappings, })).data;
  },
  async fetchImageUploadBatchToken(count = 1) {
    return (await firebase.app().functions('asia-east2').httpsCallable('wins-fetchImageUploadBatchToken')({ count })).data;
  },
  async getBatchUploadJobById(id) {
    return (await firebase.app().functions('asia-east2').httpsCallable('wins-getBatchUploadJobById')({ id })).data;
  },

  // Work Locations & Floor Plans
  async upsertWorkLocation(payload) {
    return (await firebase.app().functions('asia-east2').httpsCallable('wins-upsertWorkLocation')(payload)).data;
  },
  async deleteWorkLocation(id) {
    return (await firebase.app().functions('asia-east2').httpsCallable('wins-upsertWorkLocation')({ id, status: 'trashed' })).data;
  },
  async updateWorkLocationFloorPlan(id, photo = null, floorPlanImgWidth = null, floorPlanImgHeight = null, orientation = null) {
    const floorPlanPhotoLink = photo ? (await uploadImage(photo.base64Data, `${id}-floorPlanPhotoLink}`, 'ol')) : "";
    await firebase.app().functions('asia-east2').httpsCallable('wins-upsertWorkLocation')({ id, floorPlanPhotoLink, floorPlanImgWidth, floorPlanImgHeight, orientation, });
    return floorPlanPhotoLink;
  },

  // Project Users
  async upsertProjectUser(payload) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-upsertProjectUser')(payload)).data;
  },
  async deleteProjectUser(id) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-deleteProjectUser')({ id })).data;
  },
}